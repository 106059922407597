import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Widget } from '@typeform/embed-react'
import ScrollAnimation from 'react-animate-on-scroll';

import Header from '../../components/header';
import Footer from '../../components/footer';

import "./styles.css"

function Legal() {
  return (
    <div className="main_container">
      <Header
        selected={"legal"}
      />
      <div className='main_div legal'>
        <h1>Legal notice</h1><br/>
        <p>The website Mystena (hereinafter "the Site") is operated by the company MQL SOLUTION, whose legal information is provided below. By accessing and using this site, you agree to the following legal notices.
        <br/><br/>
          1. Information about the publisher of the site
          <br/>
          The Site is published by:<br/>
          MQL SOLUTION<br/>
          Limited liability company (SARL)<br/>
          Company Registration Number (RCS): 917471591<br/>
          Registered office: 38 Rue Montcalm 17000 La Rochelle<br/>
          Email address: contact@mqlsolution.com<br/>
          <br/><br/>
          2. Publication Director<br/>

          The publication director of the Site is:<br/>
          Jonas Gaborit<br/>
          <br/>
          3. Hosting
          <br/>
          The Site is hosted by:<br/>
          Firebase Hosting by Google<br/>
          Address: Google LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043 USA<br/>
          <br/>
          4. Intellectual Property<br/>

          The Site and all its elements (texts, images, logos, videos, etc.) are the exclusive property of MQL SOLUTION or its partners and are protected by intellectual property laws. Any reproduction, distribution, modification, or exploitation of these elements without prior authorization is strictly prohibited.
          <br/><br/>
          5. Personal Data
          <br/>
          The company MQL SOLUTION is committed to respecting the confidentiality of the personal data of Site users. The information collected is intended for managing requests and improving our services. For more information about how we handle your personal data, please refer to our Privacy Policy [link to the privacy policy].
          <br/><br/>
          6. Cookies
          <br/>
          The Site uses cookies to enhance the user experience. Cookies are files stored on your device that allow us to offer you a personalized service. You can set your browser to refuse cookies, but this may affect the use of the Site.
          <br/><br/>
          7. Liability
          <br/>
          The information on the Site is provided for informational purposes only. MQL SOLUTION cannot be held responsible for any errors, omissions, or use of the information contained on the Site. The Site may contain links to other websites, but MQL SOLUTION cannot be held responsible for the content of these sites.
          <br/><br/>
          8. Modifications to the Legal Notice
          <br/>
          MQL SOLUTION reserves the right to modify these legal notices at any time. Any modifications will be published on this page with the mention of the update date.
          <br/><br/>
          9. Applicable Law
          <br/>
          These legal notices are governed by the laws of France. In the event of a dispute, the competent courts will be those of the jurisdiction of the registered office of MQL SOLUTION.</p>
          <br/><br/>
      </div>
      <Footer />
    </div>
  );
}

export default Legal;
