import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import 'swiper/css';
import "animate.css/animate.compat.css"
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import './App.css';

import Home from './pages';
import Legal from './pages/legal';
import Data from './pages/data';

const mainrouter = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to={"/"} replace/>,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/legal-notice",
    element: <Legal />,
  },
  {
    path: "/privacy-policy",
    element: <Data />,
  },
]);

ReactGA.initialize("G-1J26F3CPMM");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAINE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINDSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

initializeApp(firebaseConfig);

function App() {

  useEffect(() => {
    
  });

  return (
    <RouterProvider router={mainrouter} />
  );
}

export default App;
