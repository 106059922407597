import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Widget } from '@typeform/embed-react'
import ScrollAnimation from 'react-animate-on-scroll';

import logo from '../assets/logo.svg';
import logoOf from '../assets/logos/logo-onlyfans.svg';
import logoMym from '../assets/logos/logo-mym.svg';
import logoFansly from '../assets/logos/logo-fansly.svg';
import imgDashboard from '../assets/images/dashboard.svg';
import imgModelsPc from '../assets/images/models_pc.webp';
import imgModelsTablet from "../assets/images/models_tablet.webp";
import imgModelsMobile from '../assets/images/models_mobile.webp';
import imgSupport from '../assets/images/support.webp';
import imgRevenue from '../assets/images/revenue.webp';
import imgManagement from '../assets/images/management.webp';
import imgExclusive from '../assets/images/exclusive.svg';
import imgMonitoring from '../assets/images/monitoring.webp';
import imgResult from '../assets/images/result.svg';
import imgPayment from '../assets/images/payment.svg';
import imgPremium from '../assets/images/premium.webp';

import Header from '../components/header';
import Footer from '../components/footer';
import CardLarge from '../components/cardlarge';
import CardVertical from '../components/cardvertical';
import CardPhone from '../components/cardphone';
import Card from '../components/card';
import Swipper from '../components/swipper';

import '../App.css';

const slider1Data = [
  {
    icon: <FontAwesomeIcon icon={icon({name: 'face-smile-beam', family: 'classic', style: "light"})} />,
    title: "Turn your passion into success.",
    text: "Let Mystena take care of your image while you focus on what you truly love."
  },
  {
    icon: <FontAwesomeIcon icon={icon({name: 'chart-mixed-up-circle-dollar', family: 'classic', style: "light"})} />,
    title: "Measurable results, proven growth.",
    text: "Weekly payments, strong partnerships, and 24/7 support: everything is optimized for your success."
  },
  {
    icon: <FontAwesomeIcon icon={icon({name: 'handshake', family: 'classic', style: "light"})} />,
    title: "Don’t miss out on any opportunity",
    text: "Every second counts. Join Mystena to secure your spot at the top, with fast earnings, powerful connections, and unwavering support."
  },
]

function Home() {
  const [header, setHeader] = useState("home");
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => setScrollPosition(window.pageYOffset));
    return () => {
        window.removeEventListener('scroll', () => setScrollPosition(window.pageYOffset));
    };
  });

  useEffect(() => {
    const home = document.getElementById('home').getBoundingClientRect().y;
    const benefits = document.getElementById('benefits').getBoundingClientRect().y;
    const features = document.getElementById('features').getBoundingClientRect().y;
    const whyus = document.getElementById('why-us').getBoundingClientRect().y;
    const contact = document.getElementById('get-started').getBoundingClientRect().y;

    console.log("home", home)
    console.log("benefits", benefits)
    console.log("features", features)
    console.log("contact", contact)
    if (contact < 100) {
      setHeader("started")
    } else if (whyus < 100) {
      setHeader("why-us")
    } else if (features < 100) {
      setHeader("features")
    } else if (benefits < 100) {
      setHeader("benefits")
    } else {
      setHeader("home")
    }
  }, [scrollPosition]);

  return (
    <div className="main_container">
      <Header
        selected={header}
      />
      <div className='main_div'>
        <div className='section section_home' id='home'> 
          <div className='section_home_toptitle'>
            <FontAwesomeIcon icon={icon({name: 'laptop', family: 'classic', style: 'solid'})} />
            You’re an adult content creator.
          </div>
          <h1>
            Take your career to the&nbsp;<span>next&nbsp;level.</span>
          </h1>
          <p>
            Mystena is an all-in-one solution designed to support you in expanding your career, maximizing your audience, <span>and boosting your earnings.</span>
          </p>
          <a
            href='#get-started'
            className='section_home_button'
          >
            <div className='section_home_button_block'>
              Get started
            </div>
          </a>
          <div className='section_home_button_under'>
            No commitments.
          </div>
          <div className='section_home_platform_container'>
            <img
              src={logoOf}
              alt='logo Onlyfans'
              loading='lazy'
            />
            <img
              src={logoMym}
              alt='logo MyM'
              loading='lazy'
            />
            <img
              src={logoFansly}
              alt='logo Fansly'
              loading='lazy'
            />
          </div>
          <div className='section_home_img_dashboard'>
            <img
              src={imgDashboard}
              alt=''
              loading='lazy'
            />
            <div className='section_home_img_dashboard_text'>
              🎉&nbsp;Over 1 million followers combined on our Instagram pages.&nbsp;🎉
            </div>
          </div>
          <div className='section_home_img_people'>
            <img
              src={imgModelsPc}
              alt=''
              loading='lazy'
              className='section_home_img_people_comp'
            />
            <img
              src={imgModelsTablet}
              alt=''
              loading='lazy'
              className='section_home_img_people_tablet'
            />
            <img
              src={imgModelsMobile}
              alt=''
              loading='lazy'
              className='section_home_img_people_mobile'
            />
          </div>
        </div>
        <div className='section section_benefits' id='benefits'>
          <h2 className='section_title'>
            The benefits of being with us
          </h2>
          <p className='section_description'>
            Discover how our expertise can propel your career and <span>unlock new opportunities.</span>
          </p>
          <div className='section_benefits_cards_container'>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardLarge
                title={<><span>Tailored</span><br/> support.</>}
                text={"At Mystena, every creator is unique. That’s why we offer personalized support to grow your presence and maximize your potential."}
                img={imgSupport}
                imgClassname={'imgsupport'}
              />
            </ScrollAnimation>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardLarge
                title={<><span>Complete management</span><br/> of your image.</>}
                text={"Don’t worry about the details. We handle everything, from managing your social media to creating your content strategy. You can focus on what you do best: creating and captivating."}
                img={imgManagement}
                imgClassname={'imgmanagement'}
                reverse
              />
            </ScrollAnimation>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardLarge
                title={<><span>Maximizing</span><br/> your revenue.</>}
                text={"Our team employs the best conversion strategies to maximize your earnings, carefully optimizing every opportunity to drive the highest possible revenue and ensure consistent growth."}
                img={imgRevenue}
                imgClassname={'imgrevenue'}
              />
            </ScrollAnimation>
            <div className='section_benefits_cardsvertical_container'>
              <ScrollAnimation
                initiallyVisible={false}
                animateOnce
                animateIn="fadeIn"
                className='section_benefits_cardsvertical_animation'
                duration={1}
              > 
                <CardVertical
                  title={<><span>Exclusive</span><br/> opportunities.</>}
                  text={"By working with Mystena, you gain access to a powerful network of opportunities to advance your career."}
                  img={imgExclusive}
                  imgClassname={'imgexclusive'}
                />
              </ScrollAnimation>
              <ScrollAnimation
                initiallyVisible={false}
                animateOnce
                animateIn="fadeIn"
                className='section_benefits_cardsvertical_animation'
                duration={1}
              > 
                <CardVertical
                  title={<><span>Continuous</span><br/> monitoring and&nbsp;analysis.</>}
                  text={<>We don’t just guide you. <br/>We&nbsp;support you every step of the way. With regular reports and in-depth performance analysis, we continuously refine our approach to ensure your long-term success.</>}
                  img={imgMonitoring}
                  imgClassname={'imgmonitoring'}
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className='section section_features' id='features'>
          <h2 className='section_title'>
            Everything you need to succeed.<br/> <span>The choice is yours.</span>
          </h2>
          <p className='section_description'>
            Customized strategies, premium 24/7 support, and results visible from the first month. Every week, your efforts are rewarded with a payout. Everything is designed to move your career forward.
          </p>
          <div className='section_features_cards_container'>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardPhone
                img={imgResult}
                title={"Tangible results, visible from the first month."}
                text={<>Tangible results: <span>up to 50% growth</span> visible from the very first month.</>}
                icon={<>🤑</>}
              />
            </ScrollAnimation>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardPhone
                img={imgPayment}
                title={"A payment every week."}
                text={<><span>Instant transfers</span> every week, rewarding your efforts without delay.</>}
                icon={<>🏦</>}
              />
            </ScrollAnimation>
            <ScrollAnimation
              initiallyVisible={false}
              animateOnce
              animateIn="fadeIn"
              duration={1}
            > 
              <CardPhone
                img={imgPremium}
                title={"Premium support, 24/7."}
                text={<>Premium support, <span>available 24/7</span> on WhatsApp, to assist you anytime.</>}
                icon={<>🧑‍💻</>}
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className='section section_features2' id='why-us'>
        <h2 className='section_title'>
          So many reasons to&nbsp;choose<br/> Mystena for <span>your&nbsp;career.</span>
        </h2>
        <div className='section_features2_cards_container'>
          {slider1Data.map((item) => (
            <Card
              icon={item.icon}
              title={item.title}
              text={item.text}
            />
          ))}
        </div>
        <Swipper
          data={slider1Data}
          buttons
          id={"swiper1"}
        />
      </div>
      <div className='section section_getstarted' id='get-started'>
        <h2 className='section_title'>
          What if we talked about it <span>concretely</span>&nbsp;?
        </h2>
        <p className='section_description'>
          Before starting anything, we need to get in touch with you to better understand your expectations. <span> Feel free to tell us about your project.</span>
        </p>
        <div className='section_getstarted_typeform_container'>
          <Widget
            id="vHV6hKFf?typeform-welcome=0"
            className='section_getstarted_typeform'
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
