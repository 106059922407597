import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Widget } from '@typeform/embed-react'
import ScrollAnimation from 'react-animate-on-scroll';

import Header from '../../components/header';
import Footer from '../../components/footer';

function Data() {
  return (
    <div className="main_container">
      <Header
        selected={"legal"}
      />
      <div className='main_div legal'>
        <h1>Privacy Policy</h1><br/>
        <p>The protection of your personal data is a priority for MQL SOLUTION. This privacy policy describes how we collect, use, and protect your personal information when you use our website Mystena. By accessing our website, you agree to the practices described in this policy.
        <br/><br/>
            1. Data Controller<br/>

            The data controller for personal data collected on the Mystena website is:<br/>
            MQL SOLUTION<br/>
            Limited liability company (SARL)<br/>
            Company Registration Number (RCS): 917471591<br/>
            Registered office: 38 Rue Montcalm 17000 La Rochelle<br/>
            Email address: contact@mqlsolution.com<br/>
            <br/>
            2. Types of Data Collected<br/>
            We collect different categories of personal data, depending on your use of the site:<br/>

            Identification data: name, first name, email address, phone number.<br/>
            Browsing data: IP address, browser type, pages viewed, duration of the visit.<br/>
            <br/>
            3. Purposes of Data Processing<br/>

            The personal data we collect is used for the following purposes:<br/>

            Managing user requests: responding to questions and contact requests.<br/>
            Improving services: analyzing how you use the site to enhance our offerings.<br/>
            Commercial transactions: managing purchases, subscriptions, and paid services offered on our site.<br/>
            Sending marketing information: with your consent, you will receive information about our services, offers, and news.<br/><br/>
            4. Legal Basis for Processing<br/>

            The processing of your personal data is based on the following legal grounds:<br/>

            Performance of a contract: to manage purchases, services, and requests.<br/>
            Consent: for sending marketing communications.<br/>
            Legitimate interest: to improve our services and provide a better user experience.<br/><br/>
            5. Data Sharing<br/>

            MQL SOLUTION does not sell, rent, or share your personal data with third parties without your consent, except in the following cases:<br/>

            Service providers: We use service providers for functions such as hosting, payment processing, and communication sending. These service providers only have access to your data for the performance of their services.<br/>
            Legal obligations: We may be required to share your information if the law demands it (e.g., in the case of a legal request).<br/><br/>
            6. Data Retention Period<br/>

            We retain your personal data only for as long as necessary to fulfill the purpose for which it was collected. This may range from a few months to several years depending on legal or contractual obligations.<br/>
            <br/>
            7. Data Security<br/>

            MQL SOLUTION implements appropriate technical and organizational security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no security system is completely infallible, and we encourage you to take precautions when using our services.<br/>
            <br/>
            8. Your Rights on Your Personal Data<br/>

            Under the General Data Protection Regulation (GDPR), you have the following rights regarding your personal data:
            <br/>
            Right of access: You have the right to request a copy of the data we hold about you.<br/>
            Right of rectification: You can request the correction of incorrect or incomplete data.<br/>
            Right to erasure: You can request the deletion of your personal data, under certain conditions.<br/>
            Right to object: You have the right to object to the use of your data for direct marketing purposes.<br/>
            Right to portability: You have the right to receive your data in a structured, machine-readable format and transmit it to another data controller.<br/>
            Right to withdraw consent: You can withdraw your consent to the use of your data for marketing purposes at any time.<br/>
            To exercise your rights, you can contact us at the following address: contact@mystena.com.<br/>
            <br/>
            9. Cookies<br/>

            The Mystena website uses cookies to enhance your online experience and analyze site usage. Cookies are small files stored on your device that allow us to provide you with a personalized service. You can manage your cookie preferences through your browser settings.
            <br/><br/>
            10. Modifications to the Privacy Policy<br/>

            MQL SOLUTION reserves the right to modify this privacy policy at any time. Any changes will be published on this page with the update date.
            <br/><br/>
            11. Applicable Law
            <br/>
            This privacy policy is governed by the laws of France. In the event of a dispute, the competent courts will be those of the jurisdiction of the registered office of MQL SOLUTION.</p>
          <br/><br/>
      </div>
      <Footer />
    </div>
  );
}

export default Data;
