import React from 'react';
import './styles.css';

function CardPhone({
  img,
  title,
  text,
  icon
}) {
  return (
    <div className="cardphone">
      <div className='cardphone_img_container'>
        <img
          src={img}
          alt=''
          loading='lazy'
        />
      </div>
      <div className='cardphone_bottom'>
        <div className='cardphone_icon'>
          {icon}
        </div>
        <div className='cardphone_title'>
          {title}
        </div>
        <p className='cardphone_text'>
          {text}
        </p>
      </div>
    </div>
  );
}

export default CardPhone;
