import React from 'react';

import './styles.css';

function Card({
  icon,
  title,
  text,
}) {
  return (
    <div className="card">
      <div className='card_icon'>
        {icon}
      </div>
      <div className='card_title'>
        {title}
      </div>
      <p className='card_text'>
        {text}
      </p>
    </div>
  );
}

export default Card;
