import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './styles.css'


import logoImg from '../../assets/logo.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className='footer_logo'>
        <img loading='lazy'
          src={logoImg}
          alt='Logo Suncore'
        />
        <a
          href='https://x.com/Mystenaagency'
          className='footer_twitter'
          target='_blank'
        >
          <FontAwesomeIcon icon={icon({name: 'x-twitter', family: 'classic', style: "brands"})} />
        </a>
      </div>
      <div className='footer_separator'/>
      <div className='footer_bottom'>
        <div className='footer_bottom_copyright'>
          Copyright © 2024 Mystena. All rights reserved.
        </div>
        <div className='footer_bottom_link_container'>
          <a href='/legal-notice' className='footer_bottom_link'>
            Legal notice
          </a>
          <a href='/privacy-policy' className='footer_bottom_link'>
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
