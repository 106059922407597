import React from 'react';

import './styles.css';

import logo from '../../assets/logo.svg';

function Header({
  selected
}) {
  return (
    <header className="header">
        <div className='header_container'>
          <a
            href='/'
          >
            <img
              src={logo}
              alt='logo Mystena'
              className='header_logo'
              loading='lazy'
            />
          </a>
          <nav className='header_nav'>
            <a
              href='/#home'
              className={selected === "home" ? 'header_nav_button header_nav_button_selected' : 'header_nav_button'}
            >
              Home
            </a>
            <a
              href='/#benefits'
              className={selected === "benefits" ? 'header_nav_button header_nav_button_selected' : 'header_nav_button'}
            >
              Benefits
            </a>
            <a
              href='/#features'
              className={selected === "features" ? 'header_nav_button header_nav_button_selected' : 'header_nav_button'}
            >
              Features
            </a>
            <a
              href='/#why-us'
              className={selected === "why-us" ? 'header_nav_button header_nav_button_selected' : 'header_nav_button'}
            >
              Why us
            </a>
            <a
              href='/#get-started'
              className={selected === "started" ? 'header_nav_button header_nav_button_selected' : 'header_nav_button'}
            >
              Contact
            </a>
          </nav>
          <div className='header_button_container'>
            <a
              href='/#get-started'
              className='header_button'
            >
              Get started
            </a>
          </div>
        </div>
      </header>
  );
}

export default Header;
