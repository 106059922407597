import React from 'react';

import './styles.css';

function CardVertical({
  img,
  title,
  text,
  imgClassname,
}) {
  return (
    <div className={"cardvertical"}>
      <div className='cardvertical_side'>
        <h3 className='cardvertical_title'>
          {title}
        </h3>
        <p className='cardvertical_text'>
          {text}
        </p>
      </div>
      <div className='cardvertical_side'>
        <img
          src={img}
          className={imgClassname}
          alt=''
          loading='lazy'
        />
      </div>
    </div>
  );
}

export default CardVertical;
