import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Swiper, SwiperSlide } from 'swiper/react';
import './styles.css'

import Card from '../card';

function Swipper({data, type, buttons, nameslide, id}) {

  const slidernameRef = useRef(null)
  const [swipperRef, setswipperRef] = useState(null)
  const [swipperPosition, setswipperPosition] = useState("start")
  const [swipperIndex, setswipperIndex] = useState(0)

  let startelement;


  return (
    <div className='swipper_container'>
      <Swiper
        slidesPerView={"auto"}
        slidesPerGroup={1}
        spaceBetween={20}
        cssMode={true}
        className="swipper"  
        rewind={false}
        centeredSlides
        onSwiper={(e) => {
          setswipperRef(e)
        }}
        onRealIndexChange={e => {
          console.log(e.progress)
          setswipperIndex(e.activeIndex)
          if (e.activeIndex === 0) {
            setswipperPosition("start")
          } else if (e.progress < 0.8) {
            setswipperPosition("middle")
          } else if (e.isEnd) {
            setswipperPosition("end")
          }
        }} 
        onReachBeginning={() => {
          setswipperPosition("start")
        }}
        onReachEnd={(e) => {
          setswipperPosition("end")
        }}
      > 
          {data.map((item, i) => {
            return (
              <SwiperSlide className='swipperslide'>
                <Card
                  icon={item.icon}
                  title={item.title}
                  text={item.text}
                />
              </SwiperSlide>
            )
        })}
      </Swiper>
      <div className='swipper_bottom_container'>
        {buttons && (
          <div className='swipper_bottom_button_container'>
            <button
              type='button'
              disabled={swipperPosition === "start"}
              className={swipperPosition === "start" ? 'swipper_bottom_button swipper_bottom_button_disable' : 'swipper_bottom_button'}
              onClick={() => {
                swipperRef.slidePrev();
                setswipperPosition("middle");
              }}
            >
              <FontAwesomeIcon icon={solid("angle-left")}/>
            </button>
            <button
              type='button'
              disabled={swipperPosition === "end"}
              className={swipperPosition === "end" ? 'swipper_bottom_button swipper_bottom_button_disable' : 'swipper_bottom_button'}
              onClick={() => {
                swipperRef.slideNext();
              }}
            >
              <FontAwesomeIcon icon={solid("angle-right")}/>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Swipper;
