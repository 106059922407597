import React from 'react';

import './styles.css';

function CardLarge({
  img,
  title,
  text,
  imgClassname,
  reverse,
}) {
  return (
    <div className={reverse ? "cardlarge cardlarge_reverse" : "cardlarge"}>
      <div className='cardlarge_side'>
        <h3 className='cardlarge_title'>
          {title}
        </h3>
        <p className='cardlarge_text'>
          {text}
        </p>
      </div>
      <div className='cardlarge_side cardlarge_img'>
        <img
          src={img}
          alt=''
          className={imgClassname}
          loading='lazy'
        />
      </div>
    </div>
  );
}

export default CardLarge;
